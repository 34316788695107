import {
  CrudService,
  UserService,
  ProductService,
  CartService,
  OrderService,
  CommonService,
  NotificationService,
  SalesPersonService,
} from './services';
import ApiServiceDataStore from './services/data';

export default class EdsApiClient {
  public crud: CrudService;
  public user: UserService;
  public product: ProductService;
  public cart: CartService;
  public order: OrderService;
  public common: CommonService;
  public notification: NotificationService;
  public salesperson: SalesPersonService;
  private store: ApiServiceDataStore = {
    apiDomain: '',
    authToken: undefined,
    customerToken: undefined,
  };

  constructor(apiDomain: string) {
    this.store.apiDomain = apiDomain;
    this.crud = new CrudService(this.store);
    this.user = new UserService(this.store);
    this.product = new ProductService(this.store);
    this.cart = new CartService(this.store);
    this.order = new OrderService(this.store);
    this.common = new CommonService(this.store);
    this.notification = new NotificationService(this.store);
    this.salesperson = new SalesPersonService(this.store);
  }

  public setToken(token?: string) {
    this.store.authToken = token;
  }
  public setCustomerToken(token?: string) {
    this.store.customerToken = token;
  }
}
