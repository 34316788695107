export enum RouteKeys {
  Home = '/',
  ForgotPassword = '/forgot-password',
  NotFound = '/page-not-found',
  SearchResult = '/search-result',
  Checkout = '/checkout',
  MyOrder = '/my-order/:status?',
  MyAccount = '/my-account',
  UserManagement = '/my-account/user-management',
  FAQ = '/faq',
  TermsOfService = '/terms-of-use',
  PrivacyPolicy = '/privacy-policy',
  SignUp = '/sign-up',
  BulkOrder = '/bulk-order',
}
