import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'product';

export default class UserService extends ApiService {
  public async searchProduct(
    searchKey: string,
    page: number,
    filters?: any,
    sort?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/productsList/website_id/1/subsite_id/1/languageID/1/product`;
    const data = new FormData();

    for (const key of Object.keys(filters)) {
      if (filters[key] && filters[key] !== '0') {
        data.append(key, filters[key]);
      }
    }
    data.append('page', page.toString());
    if (searchKey) {
      data.append('term', searchKey);
    }

    if (sort) {
      data.append('sortBy', sort);
    }
    data.append('userToken', this.token ? this.token : '');
    const response = await http.postAsFormData(url, data);
    return { data: response.data };
  }

  public async productDetails(productID: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/details/website_id/1/subsite_id/1/languageID/1/productID/${productID}`;
    const data = new FormData();
    const response = await http.get(url);
    return { data: response.data };
  }
  public async getFrequentOrders(page: number) {
    const url = `${
      this.apiDomain
    }/order/frequentorders/website_id/1/subsite_id/1/languageID/1/userToken/${
      this.token ? this.token : ''
    }/page/${page}`;

    const response = await http.get(url);
    return { data: response.data };
  }
}
