import { Dispatch } from 'redux';

import api from '../../api';
import { types as commonTypes } from './common';
import { Product } from '../../models/product';

// action types
export const types = {
  // Get product list
  SEARCH_PRODUCT_REQUEST: 'PRODUCT/SEARCH_PRODUCT_REQUEST',
  SEARCH_PRODUCT_SUCCESS: 'PRODUCT/SEARCH_PRODUCT_SUCCESS',
  SEARCH_PRODUCT_ERROR: 'PRODUCT/SEARCH_PRODUCT_ERROR',

  // Get product details
  PRODUCT_DETAIL_REQUEST: 'PRODUCT/PRODUCT_DETAIL_REQUEST',
  PRODUCT_DETAIL_SUCCESS: 'PRODUCT/PRODUCT_DETAIL_SUCCESS',
  PRODUCT_DETAIL_ERROR: 'PRODUCT/PRODUCT_DETAIL_ERROR',

  FREQUENT_ORDER_REQUEST: 'CART/FREQUENT_ORDER_REQUEST',
  FREQUENT_ORDER_SUCCESS: 'CART/FREQUENT_ORDER_SUCCESS',
  FREQUENT_ORDER_ERROR: 'CART/FREQUENT_ORDER_ERROR',

  RESET_DATA: 'CART/RESET_DATA',
};

export interface ProductState {
  products?: Product[];
  searchCompleted: boolean;
  isSearching: boolean;
  totalCount?: number;
  totalFrequentCount?: number;
  perPageCount?: number;
  newSearch?: boolean;
  isLoadingDetails: boolean;
  isDetailsLoaded: boolean;
  product?: Product;
  productID?: number;
  isProductLoading: boolean;
  isProductLoaded: boolean;
  frequentOrders?: Product[];
  productFilter?: any;
}

const initialState: ProductState = {
  searchCompleted: false,
  isSearching: false,
  newSearch: false,
  isLoadingDetails: false,
  isDetailsLoaded: false,
  productID: 0,
  isProductLoading: false,
  isProductLoaded: false,
};

export default (
  state: ProductState = initialState,
  action: any
): ProductState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };

    // product list
    case types.SEARCH_PRODUCT_REQUEST:
      return {
        ...state,
        isSearching: true,
        newSearch: action.newSearch,
      };
    case types.SEARCH_PRODUCT_SUCCESS:
      const products =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [...(state.products ? state.products : []), ...action.data.result];

      return {
        ...state,
        searchCompleted: !!action.data,
        products,
        totalCount: action.data ? action.data.totalCount : 1,
        perPageCount: action.data ? Number(action.data.perPageCount) : 10,
        isSearching: false,
        newSearch: false,
        productFilter: action.data ? action.data.filter : null,
      };
    case types.SEARCH_PRODUCT_ERROR:
      return {
        ...state,
        isSearching: false,
        products: [],
        totalCount: 0,
        searchCompleted: true,
        newSearch: false,
      };

    case types.PRODUCT_DETAIL_REQUEST:
      return { ...state, isLoadingDetails: true, isDetailsLoaded: false };
    case types.PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        isDetailsLoaded: !!action.data,
        product: action.data ? action.data.result[0] : null,
        isLoadingDetails: false,
      };
    case types.PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isLoadingDetails: false,
        isDetailsLoaded: true,
      };
    case types.FREQUENT_ORDER_REQUEST:
      return {
        ...state,
        isProductLoading: true,
        isProductLoaded: false,
      };
    case types.FREQUENT_ORDER_SUCCESS:
      const frequentOrders =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.frequentOrders ? state.frequentOrders : []),
              ...action.data.result,
            ];

      return {
        ...state,
        frequentOrders,
        isProductLoading: false,
        isProductLoaded: true,
        totalFrequentCount: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
      };
    case types.FREQUENT_ORDER_ERROR:
      return {
        ...state,
        isProductLoading: false,
        isProductLoaded: true,
        totalFrequentCount: 0,
      };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  searchProduct: (
    searchKey: string,
    page: number,
    filters?: any,
    sort?: string,
    newSearch?: boolean
  ) => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);

    dispatch({ type: types.SEARCH_PRODUCT_REQUEST, newSearch });
    try {
      const response = await api.product.searchProduct(
        searchKey,
        page,
        filters,
        sort
      );
      const { data } = response;
      dispatch({ type: types.SEARCH_PRODUCT_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.SEARCH_PRODUCT_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },

  productDetails: (productID: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);

    dispatch({ type: types.PRODUCT_DETAIL_REQUEST });
    try {
      const response = await api.product.productDetails(productID);
      const { data } = response;
      dispatch({ type: types.PRODUCT_DETAIL_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.PRODUCT_DETAIL_ERROR });
      throw error;
    }
  },
  getFrequentOrders: (page: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.FREQUENT_ORDER_REQUEST });
    try {
      const response = await api.product.getFrequentOrders(page);
      const { data } = response;
      dispatch({ type: types.FREQUENT_ORDER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.FREQUENT_ORDER_ERROR });
      throw error;
    }
  },
};
