import * as http from './http';
import ApiService from './service';

export default class NotificationService extends ApiService {
  public async getNotification(page: number) {
    const url = `${this.apiDomain}/notification/website_id/1/userToken/${
      this.token ? this.token : ''
    }/subsite_id/1/languageID/1/page/${page}`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async changeReadStatus() {
    const url = `${this.apiDomain}/notification/read/website_id/1/userToken/${
      this.token ? this.token : ''
    }/subsite_id/1/languageID/1`;

    const data = new FormData();

    // data.append('userToken', this.token ? this.token : '');

    // data.append('website_id', '1');
    // data.append('subsite_id', '1');
    // data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }
}
