import { stringify } from 'query-string';
import * as http from './http';
import ApiService from './service';
import { Product } from '../../models/product';
import { CartItem, CheckoutSubmit } from '../../models';
import { OrderConfirm, BulkOrderUpdate } from '../../models/order';

const serviceEndpoint = 'order';

export default class OrderService extends ApiService {
  public async getOrderCount(filters?: any) {
    let url = `${this.apiDomain}/${serviceEndpoint}/ordercount/website_id/1/userToken/${this.token}/subsite_id/1/languageID/1`;
    const data = new FormData();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }
    return http.get(url);
  }
  public async getOrderList(page: number, filters?: any) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/orderlist/website_id/1/subsite_id/1/languageID/1/userToken/${
      this.token ? this.token : ''
    }/page/${page}`;
    const data = new FormData();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }

    const response = await http.get(url);
    return { data: response.data };
  }

  public async getOrderDetails(orderID: number) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/orderlist/website_id/1/subsite_id/1/languageID/1/userToken/${
      this.token ? this.token : ''
    }/orderID/${orderID}`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async changeOrderStatus(
    orderID: number,
    status: string,
    reason?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/orderstatus`;
    const data = new FormData();

    data.append('orderID', orderID ? orderID.toString() : '');
    data.append('status', status);
    data.append('reason', reason ? reason : '');
    data.append('userToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async highOrderRequest(productID: number, orderQty: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/highorder`;
    const data = new FormData();

    data.append('productID', productID ? productID.toString() : '');
    data.append('orderQty', orderQty ? orderQty.toString() : '');
    data.append('userToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async getOrderFilter() {
    const url = `${this.apiDomain}/${serviceEndpoint}/filter/website_id/1/userToken/${this.token}/subsite_id/1/languageID/1`;

    return http.get(url);
  }

  public async getPaymentList() {
    const url = `${this.apiDomain}/${serviceEndpoint}/paymentlist/website_id/1/userToken/${this.token}/subsite_id/1/languageID/1`;

    return http.get(url);
  }

  public async orderConfirm(item: OrderConfirm) {
    const url = `${this.apiDomain}/${serviceEndpoint}/confirm`;
    const data = new FormData();

    data.append('orderID', item.orderID.toString());
    data.append(
      'paymentSettingsGroupKeySelected',
      item.paymentSettingsGroupKeySelected
    );

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (item.purchaseFile) {
      data.append('purchaseFile', item.purchaseFile);
    }

    if (item.poDate) {
      data.append('poDate', item.poDate);
    }
    if (item.poNumber) {
      data.append('poNumber', item.poNumber);
    }
    if (item.cardNumber) {
      data.append('cardNumber', item.cardNumber);
    }
    if (item.nameOnCard) {
      data.append('nameOnCard', item.nameOnCard);
    }
    if (item.cvv) {
      data.append('cvv', item.cvv);
    }
    if (item.expiryYear) {
      data.append('expiryYear', item.expiryYear);
    }
    if (item.expiryMonth) {
      data.append('expiryMonth', item.expiryMonth);
    }

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async bulkOrder(csvFile: any) {
    const url = `${this.apiDomain}/bulkorder/csvupload`;
    const data = new FormData();

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }

    data.append('csvFile', csvFile);

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async updateBulkOrder(item: BulkOrderUpdate) {
    const url = `${this.apiDomain}/bulkorder/edit`;
    const data = new FormData();

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }

    data.append('productID', item.productID ? item.productID.toString() : '');
    data.append(
      'productOptionID',
      item.productOptionID ? item.productOptionID.toString() : ''
    );
    data.append('userID', item.userID ? item.userID.toString() : '');
    data.append('action', item.action);
    data.append('quantity', item.quantity ? item.quantity.toString() : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async addToCart(cartID?: number) {
    const url = `${this.apiDomain}/bulkorder/addToCart`;
    const data = new FormData();

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }

    if (cartID) {
      data.append('cartID', cartID ? cartID.toString() : '');
    }

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }
}
